import { signIn } from 'next-auth/react';
import { env } from 'next-runtime-env';

import { ButtonCore } from '~/components/core-components';

import { YandexLogo } from '../../../../../public/yandex-logo';

import styles from './yandex-login-button.module.css';

const DEV_HOST = 'testsympee.ru';
const serviceHost = env('NEXT_PUBLIC_HOST') || DEV_HOST;

export const YaButton = ({ acceptLegal }: { acceptLegal: boolean }) => {
    return (
        <>
            <ButtonCore
                size='large'
                view='outline'
                disabled={!acceptLegal}
                className={styles['button']}
                onClick={() => {
                    if (acceptLegal) {
                        signIn('yandex', { callbackUrl: `https://${serviceHost}/` });
                    }
                }}
            >
                <YandexLogo />
            </ButtonCore>
        </>
    );
};
